/**
 * List of country codes and flags
 * @type {Array}
 */
export const countryCodes = [
  { name: 'Chile', code: '+56', flag: '🇨🇱' },
  { name: 'Argentina', code: '+54', flag: '🇦🇷' },
  { name: 'Bolivia', code: '+591', flag: '🇧🇴' },
  { name: 'Brasil', code: '+55', flag: '🇧🇷' },
  { name: 'Colombia', code: '+57', flag: '🇨🇴' },
  { name: 'Ecuador', code: '+593', flag: '🇪🇨' },
  { name: 'España', code: '+34', flag: '🇪🇸' },
  { name: 'México', code: '+52', flag: '🇲🇽' },
  { name: 'Perú', code: '+51', flag: '🇵🇪' },
  { name: 'Uruguay', code: '+598', flag: '🇺🇾' },
  { name: 'Venezuela', code: '+58', flag: '🇻🇪' },
  // Agrega más países según sea necesario
].sort((a, b) => a.name.localeCompare(b.name, 'es'));
