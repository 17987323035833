import { useData } from 'vike-react/useData';
import { Head } from 'vike-react/Head';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Mail,
  CheckCircle,
  Shield,
  Clock,
  Search,
  Check,
  FileSearch,
  FileText,
  Eye,
  Umbrella,
  Globe,
  Gavel,
  PiggyBank,
  HeadphonesIcon,
  Menu,
  CheckCircle2,
  ArrowRight,
  Zap,
} from 'lucide-react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ContactModal from '../../components/ContactModal';
import {
  FAQSchema,
  BlogSchema,
  OrganizationSchema,
  ServiceSchema,
  WebPageSchema,
} from '../../components/Schema';
export default function Page() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const renderIcon = (iconName, className) => {
    const icons = {
      Search: <Search className={className} />,
      Mail: <Mail className={className} />,
      Shield: <Shield className={className} />,
      CheckCircle: <CheckCircle className={className} />,
      Clock: <Clock className={className} />,
      Check: <Check className={className} />,
      FileSearch: <FileSearch className={className} />,
      FileText: <FileText className={className} />,
      Eye: <Eye className={className} />,
      Umbrella: <Umbrella className={className} />,
      Globe: <Globe className={className} />,
      Gavel: <Gavel className={className} />,
      PiggyBank: <PiggyBank className={className} />,
      HeadphonesIcon: <HeadphonesIcon className={className} />,
      Menu: <Menu className={className} />,
      CheckCircle2: <CheckCircle2 className={className} />,
      Zap: <Zap className={className} />,
    };
    return icons[iconName] || null;
  };

  const data = useData();
  const {
    faqSection,
    ourPublications,
    registrationProcess,
    productList,
    whyChooseUs,
    buttonsSection,
    mainBanner,
  } = data;

  return (
    <>
      <Head>
        <meta property="og:title" content="Registro de Marcas Chile" />
        <meta name="twitter:title" content="Registro de Marcas Chile" />
        <meta name="description" content="Registro de Marcas Chile" />
        <meta property="og:description" content="Registro de Marcas Chile" />
        <meta name="twitter:description" content="Registro de Marcas Chile" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:url"
          content="https://www.registrodemarcaschile.cl"
        />
        <link rel="canonical" href="https://www.registrodemarcaschile.cl" />
        <meta name="robots" content="index, follow" />
      </Head>
      <main className="flex-grow">
        <section className="py-20 xl:py-0 bg-gradient-to-r from-blue-600 to-green-500 text-white overflow-hidden">
          <div className="container mx-auto px-4">
            <div className="flex flex-col xl:flex-row items-center">
              <div className="xl:w-1/2 mb-10 md:mb-0">
                <h1 className="text-4xl md:text-5xl font-bold mb-4 leading-tight">
                  {mainBanner ? mainBanner.title : ''}
                </h1>
                <div className="text-xl mb-8 leading-relaxed mainbannerhome">
                  {mainBanner
                    ? documentToReactComponents(mainBanner.richSubtitle)
                    : ''}
                </div>
                <div className="flex justify-center">
                  <Link
                    title="Verifica tu marca gratis"
                    to="/checkout"
                    className="inline-flex items-center justify-center whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-white text-blue-600 hover:bg-blue-100 transition-colors text-xl px-10 py-4 rounded-full shadow-lg uppercase font-bold"
                  >
                    Verifica tu marca gratis
                  </Link>
                </div>
              </div>
              <div className="xl:w-1/2 hidden xl:block">
                {mainBanner && mainBanner.imagen ? (
                  <img
                    src={mainBanner.imagen.fields.file.url}
                    alt={mainBanner.imagen.fields.file.fileName}
                    className="w-full object-cover"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </section>

        <section id="servicios" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-16 text-blue-800">
              {productList
                ? productList.title
                : 'Nuestros Planes de Registro de Marcas'}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
              {productList &&
                productList.content.map((item, index) => (
                  <div
                    key={index}
                    className="bg-gradient-to-br from-blue-50 to-green-50 rounded-xl shadow-lg p-8 transition-all duration-300 hover:shadow-xl hover:scale-105"
                  >
                    <div className="flex items-center mb-4">
                      <div className="bg-blue-500 rounded-full p-3 mr-4">
                        {item.fields.icon &&
                          renderIcon(item.fields.icon, 'w-8 h-8 text-white')}
                      </div>
                      <h3 className="text-xl font-semibold text-blue-800">
                        {item.fields.title}
                      </h3>
                    </div>
                    <p className="text-gray-600 leading-relaxed">
                      {item.fields.description}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section
          id="whychooseus"
          className="py-20 bg-gradient-to-r from-blue-500 to-green-400 text-white"
        >
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-4">
                {whyChooseUs ? whyChooseUs.title : '¿Por qué elegirnos?'}
              </h2>
              <p className="text-xl max-w-3xl mx-auto">
                {whyChooseUs ? whyChooseUs.subtitle : ''}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {whyChooseUs &&
                whyChooseUs.content.map((item, index) => (
                  <div
                    key={index}
                    className="bg-white bg-opacity-20 rounded-xl p-8 backdrop-blur-lg transition-all duration-300 hover:bg-opacity-30 hover:scale-105"
                  >
                    <div className="bg-white rounded-full w-16 h-16 flex items-center justify-center mb-6 mx-auto">
                      {item.fields.icon &&
                        renderIcon(item.fields.icon, 'w-8 h-8 text-blue-500')}
                    </div>
                    <h3 className="text-xl font-semibold mb-4 text-center">
                      {item.fields.title}
                    </h3>
                    <p className="text-center">{item.fields.description}</p>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section id="proceso" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-blue-800">
                {registrationProcess
                  ? registrationProcess.title
                  : 'Nuestro Proceso de Registro'}
              </h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                {registrationProcess ? registrationProcess.subtitle : ''}
              </p>
            </div>
            <div className="flex flex-col md:flex-row justify-between space-y-12 md:space-y-0 md:space-x-8">
              {registrationProcess &&
                registrationProcess.content.map((item, index) => (
                  <div
                    key={index}
                    className="flex-1 bg-gradient-to-br from-blue-50 to-green-50 rounded-xl p-6 transition-all duration-300 hover:shadow-lg hover:scale-105 flex flex-col"
                  >
                    <div className="bg-blue-500 rounded-full w-20 h-20 flex items-center justify-center mb-6 mx-auto">
                      {item.fields.icon &&
                        renderIcon(item.fields.icon, 'w-10 h-10 text-white')}
                    </div>
                    <h3 className="text-xl font-semibold mb-4 text-center text-blue-800 flex-grow">
                      {item.fields.title}
                    </h3>
                    <p className="text-gray-600 text-center flex-grow">
                      {item.fields.description}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section
          id="buttonsSection"
          className="bg-gradient-to-r from-blue-500 to-green-400 text-white py-20"
        >
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              {buttonsSection
                ? buttonsSection.title
                : 'Proteja el futuro de su negocio hoy'}
            </h2>
            <p className="text-xl mb-8 max-w-2xl mx-auto">
              {buttonsSection ? buttonsSection.subtitle : ''}
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <Link
                title="Verifica tu marca gratis"
                to="/checkout"
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 h-10 bg-white text-blue-600 hover:bg-blue-100 transition-colors text-lg px-8 py-3 uppercase font-bold"
              >
                Verifica tu marca gratis
              </Link>
              <button
                onClick={() => setIsContactModalOpen(true)}
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border h-10 bg-transparent text-white border-white hover:bg-white hover:text-blue-600 transition-colors text-lg px-8 py-3"
              >
                Consulta gratuita
              </button>
            </div>
          </div>
        </section>

        <section
          id="publicaciones"
          className="py-20 bg-gradient-to-r from-blue-100 to-green-100"
        >
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <Link
                title="Blog"
                to="/blog"
                className="text-3xl md:text-4xl font-bold mb-4 text-blue-800 hover:text-blue-900"
              >
                {ourPublications
                  ? ourPublications.title
                  : 'Nuestras Publicaciones'}
              </Link>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                {ourPublications ? ourPublications.subtitle : ''}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              {ourPublications &&
                ourPublications.content.slice(-3).map((item, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105"
                  >
                    <img
                      src={item.fields.image.fields.file.url}
                      alt={item.fields.title}
                      className="w-full h-48 object-cover"
                      loading="lazy"
                      decoding="async"
                    />
                    <div className="p-6">
                      <h3 className="text-xl font-semibold mb-2 text-blue-800">
                        {item.fields.title}
                      </h3>
                      <p className="text-gray-600 mb-4">
                        {item.fields.summary}
                      </p>
                      <a
                        title={item.fields.title}
                        href={`/blog/${item.fields.slug}`}
                        className="text-blue-700 hover:text-blue-900 transition-colors inline-flex items-center"
                      >
                        Leer más
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </section>

        <section id="faq" className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold mb-4 text-blue-800">
                {faqSection ? faqSection.title : 'Preguntas Frecuentes'}
              </h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                {faqSection ? faqSection.subtitle : ''}
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-8xl mx-auto">
              {faqSection &&
                faqSection.content.map((item, index) => (
                  <div key={index} className="p-4">
                    <h3 className="text-left text-lg font-semibold text-blue-800">
                      {item.fields.question}
                    </h3>
                    <p className="text-gray-600 leading-relaxed">
                      {item.fields.answer}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </main>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@graph': [
              OrganizationSchema({
                context: false,
                name: 'Registro de Marcas Chile',
                url: 'https://www.registrodemarcaschile.cl',
                urlLogo: 'https://www.registrodemarcaschile.cl/logo.png',
                relatedLinks: [
                  'https://www.instagram.com/registrodemarcaschile_oficial',
                  'https://www.tiktok.com/@registrodemarcasc',
                ],
                phone: '+56959112948',
              }),
              WebPageSchema({
                context: false,
                title: 'Registro de Marcas Chile',
                description: 'Registro de Marcas Chile',
                url: 'https://www.registrodemarcaschile.cl',
                urlImage: 'https://www.registrodemarcaschile.cl/logo.png',
              }),
              ServiceSchema({
                context: false,
                name: 'Registro de Marcas Chile',
                url: 'https://www.registrodemarcaschile.cl/checkout',
                description: 'Registro de Marcas Chile',
                organizationName: 'Registro de Marcas Chile',
                organizationUrl: 'https://www.registrodemarcaschile.cl',
              }),
              FAQSchema({ context: false, content: faqSection.content }),
              BlogSchema({ context: false, content: ourPublications.content }),
            ],
          }),
        }}
      />

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
      />
    </>
  );
}
